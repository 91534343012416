<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex mt-3 ml-3 mb-2 align-items-center">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <router-link to="/data-client/detail" class="nav-link active"
                >Karyawan</router-link
              >
            </li>
          </ul>
        </div>
        <div class="card">
          <div class="row mb-5">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-karyawan/detail" class="nav-link"
                  >Detail</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/data-karyawan/departemen"
                  class="nav-link active"
                  >Departemen
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/data-karyawan/rekening" class="nav-link"
                  >Rekening</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-karyawan/pajak" class="nav-link"
                  >Pajak</router-link
                >
              </li>
            </ul>
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped"
              id="tableDepartment"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Divisi</th>
                  <th style="width: 20%">Jabatan</th>
                  <th style="width: 15%">Mulai</th>
                  <th style="width: 15%">Selesai</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Redbull Racing Honda</td>
                  <td>CEO</td>
                  <td>28 Maret 2021</td>
                  <td>12 Desember 2020</td>
                </tr>
                <tr>
                  <td>Redbull Racing Honda</td>
                  <td>CEO</td>
                  <td>28 Maret 2021</td>
                  <td>12 Desember 2020</td>
                </tr>
                <tr>
                  <td>Redbull Racing Honda</td>
                  <td>CEO</td>
                  <td>28 Maret 2021</td>
                  <td>12 Desember 2020</td>
                </tr>
                <tr>
                  <td>Redbull Racing Honda</td>
                  <td>CEO</td>
                  <td>28 Maret 2021</td>
                  <td>12 Desember 2020</td>
                </tr>
                <tr>
                  <td>Redbull Racing Honda</td>
                  <td>CEO</td>
                  <td>28 Maret 2021</td>
                  <td>12 Desember 2020</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <table-lite :is-static-mode="true" :is-loading="table.isLoading" :columns="table.columns"
                    :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                    @is-finished="table.isLoading = false"></table-lite> -->
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
// import Select2 from 'vue3-select2-component';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2
    // TableLite
  },

  data() {
    return {
      myValue: "aaaa",
      statusCompany: ["Aktif", "Tidak Aktif"], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: ["1 Bulan", "2 Bulan"],

      placeholder: [
        {
          text: "kecamatan",
        },
      ],
    };
  },

  mounted() {
    $("#tableDepartment").DataTable({
      info: false,
    });
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },

    clickEdit() {
      $(".groupBtn-save-edit").removeClass("d-none");
      $(".groupBtn-detail").addClass("d-none");
      $("input").removeAttr("readonly");
      $(".edit-vendor ").removeClass("d-none");
      $(".input-none").addClass("d-none");
    },

    clickCancel() {
      $("input").attr("readonly");
      $(".groupBtn-detail").removeClass("d-none");
      $(".groupBtn-save-edit ").addClass("d-none");
      $("input").attr("readonly", true);
      $(".edit-vendor ").addClass("d-none");
      $(".input-none").removeClass("d-none");
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav-pills {
  margin: auto;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  padding: 20px 50px;
  widows: 100%;
}
</style>
